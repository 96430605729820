import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78')
];

export const server_loads = [];

export const dictionary = {
		"/": [~15],
		"/admin/user": [23,[4]],
		"/api": [24,[5],[6]],
		"/api/open": [25,[5],[6]],
		"/api/open/reference": [26,[5],[6]],
		"/api/open/try": [27,[5],[6]],
		"/api/postman": [28,[5],[6]],
		"/api/reference": [29,[5],[6]],
		"/api/status": [30,[5],[6]],
		"/api/try": [31,[5],[6]],
		"/(office)/company/[companyId]": [~16,[2],[3]],
		"/excel": [32,[7],[8]],
		"/excel/authentication": [33,[7],[8]],
		"/excel/installation": [34,[7],[8]],
		"/excel/queue": [35,[7],[8]],
		"/excel/search": [36,[7],[8]],
		"/login/callback": [37],
		"/me/api-key": [38,[9],[10]],
		"/me/billing": [39,[9],[10]],
		"/me/history": [40,[9],[10]],
		"/me/list": [41,[9],[10]],
		"/me/list/[listId]": [42,[9],[10]],
		"/me/notification": [43,[9],[10]],
		"/me/profile": [44,[9],[10]],
		"/me/subscription": [45,[9],[10]],
		"/me/usage": [46,[9],[10]],
		"/(office)/office": [17,[2],[3]],
		"/(office)/office/bulk": [19,[2],[3]],
		"/(office)/office/preference": [20,[2],[3]],
		"/(office)/office/[taxId]": [~18,[2],[3]],
		"/(office)/person": [21,[2],[3]],
		"/(office)/person/[personId]": [~22,[2],[3]],
		"/subscription": [~47,[11],[12]],
		"/subscription/about": [~48,[11],[12]],
		"/subscription/checkout": [~49,[11],[12]],
		"/subscription/checkout/[subscriptionId]": [50,[11],[12]],
		"/subscription/credit": [51,[11],[12]],
		"/subscription/estimate": [~52,[11],[12]],
		"/subscription/faq": [53,[11],[12]],
		"/subscription/privacy": [54,[11],[12]],
		"/subscription/tos": [55,[11],[12]],
		"/ui/anchor": [56,[13],[14]],
		"/ui/button": [57,[13],[14]],
		"/ui/cache": [58,[13],[14]],
		"/ui/card": [59,[13],[14]],
		"/ui/chart": [60,[13],[14]],
		"/ui/checkbox": [61,[13],[14]],
		"/ui/code": [62,[13],[14]],
		"/ui/copy": [63,[13],[14]],
		"/ui/credit": [64,[13],[14]],
		"/ui/divider": [65,[13],[14]],
		"/ui/form": [66,[13],[14]],
		"/ui/info": [67,[13],[14]],
		"/ui/input-chip": [69,[13],[14]],
		"/ui/input": [68,[13],[14]],
		"/ui/list": [70,[13],[14]],
		"/ui/notice": [71,[13],[14]],
		"/ui/paragraph": [72,[13],[14]],
		"/ui/select": [73,[13],[14]],
		"/ui/status": [74,[13],[14]],
		"/ui/stepper": [75,[13],[14]],
		"/ui/table": [76,[13],[14]],
		"/ui/text-area": [77,[13],[14]],
		"/ui/tooltip": [78,[13],[14]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';